/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import DocsMarkdownPage from "../../components/docs/mdx-page"
import Layout from "../../layout"
import SiteHead from "../../head"
import subnav from "./subnav"
import StructuredData from "./structured-data"
import { getOgImage } from "./get-og-image"

export function Head(props) {
  const page = props.data.mdx
  const description = page.frontmatter.description || page.excerpt

  return (
    <SiteHead
      {...props}
      title={page.frontmatter.title}
      description={description}
      image={getOgImage(props.location)}
    >
      <StructuredData {...props} />
    </SiteHead>
  )
}

// remote markdown docs on coming from the OSS repo
export default function DocsTemplate({
  data: { mdx },
  location,
  pageContext: { next, prev },
}) {
  const page = mdx
  const { frontmatter } = page

  return (
    <Layout subnav={subnav}>
      <DocsMarkdownPage page={page} location={location} prev={prev} next={next}>
        {frontmatter.issue && (
          <a
            href={page.frontmatter.issue}
            target="_blank"
            rel="noopener noreferrer"
          >
            See the issue relating to this stub on GitHub
          </a>
        )}
      </DocsMarkdownPage>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      timeToRead
      tableOfContents
      fields {
        slug
        locale
        anchor
        relPath
      }
      frontmatter {
        title
        examples {
          label
          href
        }
        description
        overview
        issue
        disableTableOfContents
        tableOfContentsDepth
      }
    }
  }
`
