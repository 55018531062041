export const getOgImage = location => {
  const imagePathPrefix = "https://www.gatsbyjs.com/og-image_docs_"
  let image

  if (location.pathname.includes("/docs/how-to")) {
    image = `${imagePathPrefix}how-to.png`
  } else if (location.pathname.includes("/docs/reference/release-notes")) {
    image = `${imagePathPrefix}release-notes.png`
  } else if (location.pathname.includes("/docs/reference")) {
    image = `${imagePathPrefix}reference.png`
  } else if (location.pathname.includes("/docs/tutorial")) {
    image = `${imagePathPrefix}tutorial.png`
  } else if (location.pathname.includes("/docs/conceptual")) {
    image = `${imagePathPrefix}conceptual.png`
  } else if (location.pathname.includes("/docs/quick-start")) {
    image = `${imagePathPrefix}quick-start.png`
  } else if (location.pathname === "/docs" || location.pathname === "/docs/") {
    image = `${imagePathPrefix}default.png`
  }

  return image
}
